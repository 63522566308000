import React, { useEffect, useState } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { useParams, useNavigate, Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleNotch, faEllipsisVertical, faFileCirclePlus, faTrash, faXmark } from '@fortawesome/free-solid-svg-icons'
import InputField from 'components/inputs/InputField'
import { toast } from 'bulma-toast'
import { customAlphabet } from 'nanoid'
import { FormInputError } from 'IndexProductScreen'
import { User } from 'ManageUsersScreen'
import { DATE_TIME_FORMAT, drawingCategories, drawingTypes } from 'constants/Constants'
import { DrawingSearchResult, DrawingType, Metadata, MetadataStatusType } from 'models/SearchResult'
import { AddAnotherBtn } from 'components/buttons/AddAnotherBtn'
import { API_URL } from 'constants/Constants'
import { TagsInput } from 'components/inputs/TagsInput'
import { PageLoadSpinner } from 'components/spinners/PageLoadSpinner'
import { Asset, DesignDocument, DesignDocumentFormState } from 'hooks/useDesignDocumentsForm'
import { Permissions } from 'AppController'
import moment from 'moment'
import { TextAreaInputField } from 'components/inputs/TextAreaInputField'
import { isMerchant } from 'models/User'


// @ts-ignore
import DrawingNotFoundImage from '../public/images/drawing_not_found_image.svg'
import { ConfirmDeleteModal } from 'components/modals/ConfirmDeleteModal'


const getApi = (objectID: string, accessToken: string): Promise<Response> => {
    return fetch(
    `${API_URL}/drawings/get/${encodeURI(objectID)}`
        , {
        method: 'GET',
        redirect: 'follow',
        headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": 'application/json'
        },
        }); 
  }


const IndexDrawingScreen = ({ loggedInUser, pageState = 'add'}:{ loggedInUser: User, pageState?: 'add' | 'edit' | 'view'}) => {
    
    const { objectID } = useParams()
    const { getAccessTokenSilently  } = useAuth0()

    // TODO -create hook - useGeneratedID
    const generateId = (): string => {
        const nanoid = customAlphabet('0123456789', 10)
        return nanoid(6)
    }

    const generatedId: string = generateId()

    const navigate = useNavigate()

    type IndexDrawingDocument = {
        objectID: string,
        code: string,
        drawingCode?: string,
        description: string,
        type: DrawingType,
        weight: number,
        tags: string[],
        designDocuments: DesignDocument[],
    }

    type IndexDrawingFormState = {
        code: string,
        drawingCode?: string,
        description: string,
        type: DrawingType,
        weight: number,
        tags: string[]
    }

    const initialFormState: IndexDrawingFormState = {
        code: '',
        drawingCode: '',
        description: '',
        type: '',
        weight: 0,
        tags: [],
    }

    const initialAssetState: Asset = {
        fileName: undefined,
        fileExtension: undefined,
        filePath: undefined,
        sourceUrl: undefined,
        isUploaded: false,
        isLoading: false,
        error: undefined
    }

    const initialDesignDocumentState: DesignDocumentFormState = {
        category: {
            category: '',
            error: null
        },
        asset: initialAssetState
    }

    const initialDesignDocumentsState: DesignDocumentFormState[] = [ initialDesignDocumentState ]

    interface FormErrorState {
        code: FormInputError,
        drawingCode: FormInputError,
        description: FormInputError,
        type: FormInputError,
        weight: FormInputError,
        tags: FormInputError,
    }

    const initialFormInputErrorState: FormInputError = {
        isError: false,
        errorMessage: null
    }

    const initialFormErrorState: FormErrorState = {
        code: initialFormInputErrorState,
        drawingCode: initialFormInputErrorState,
        description: initialFormInputErrorState,
        type: initialFormInputErrorState,
        weight: initialFormInputErrorState,
        tags: initialFormInputErrorState,
    }

    const [editObject, setEditObject] = useState<DrawingSearchResult>(null)
    const [isEditObjectLoading, setIsEditObjectLoading] = useState<boolean>(false)
    const [isRequestLoading, setIsRequestLoading] = useState<boolean>(false)
    const [isConfirmDeleteModalActive, setIsConfirmDeleteModalActive] = useState<boolean>(false)
    const [formState, setFormState] = useState<IndexDrawingFormState>(initialFormState)
    const [formErrorState, setFormErrorState] = useState<FormErrorState>(initialFormErrorState)
    const [designDocumentsState, setDesignDocumentsState] = useState<DesignDocumentFormState[]>(initialDesignDocumentsState)

    const initialiseEditObject = (editObject: DrawingSearchResult) => {
        setEditObject(editObject)
        setFormState({...editObject}) 
        setDesignDocumentsState([
            ...editObject.designDocuments.map((doc) => ({
                category: {
                    category: doc.category, 
                    error: null,
                },
                asset: {
                    fileName: doc.asset.assetFileName, 
                    fileExtension: doc.asset.assetFileExtension, 
                    filePath: doc.asset.assetPath,
                    sourceUrl: doc.asset.assetUrl,
                    isLoading: false,
                    isUploaded: true,
                }
            }))
        ])
    }

    useEffect(() => {

        if (pageState !== 'add') {
            const get = async (id: string) => {
                setIsEditObjectLoading(true)
                const accessToken: string = await getAccessTokenSilently()
                const response = await getApi(id, accessToken)
                setIsEditObjectLoading(false)

                if (response.ok) {
                    const getResponse: DrawingSearchResult = await response.json()
                    initialiseEditObject(getResponse)
                } else if (response.status === 404) {
                    setEditObject(null)
                } else {
                    navigate(`/`)
                }
            }

            get(objectID)
        } else {
            setFormState(initialFormState)
            setEditObject(null)
            setFormErrorState(initialFormErrorState)
            setDesignDocumentsState(initialDesignDocumentsState)
        }
        
    }, [pageState !== 'add'])


    const onChangeInput = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {

        setFormState({
            ...formState,
            [e.target.name]: e.target.value
        })
    }

    const buildDrawingCode = (code: string): string => `${code}-${generatedId}`

    const onChangeCode = (e: React.ChangeEvent<HTMLInputElement>) => {
        const code = e.target.value

        setFormState({
            ...formState,
            [e.target.name]: code,
            drawingCode: code ? buildDrawingCode(code) : ''
        })
    }

    const onAddTag = (fieldName: keyof Pick<IndexDrawingFormState, 'tags'>, value: string) => {
    
        setFormState({ 
            ...formState,
            [fieldName]: [...formState[fieldName], value]
        })
    }

    const onDeleteTag = (fieldName: keyof Pick<IndexDrawingFormState, 'tags'>, value: string) => {
    
        setFormState({
            ...formState,
            [fieldName]: formState[fieldName].filter((current) => current != value )
        })
    }

    const onAddDesignDocument = (e: React.MouseEvent) => {
        e.preventDefault()
    
        setDesignDocumentsState (
            [...designDocumentsState, initialDesignDocumentState]
        )
    }

    const onDeleteDesignDocument = (e: React.MouseEvent, indexToRemove: number) => {
        e.preventDefault()
    
        setDesignDocumentsState(
            designDocumentsState.filter((_, idx) => idx != indexToRemove)
        )
    }

    const onChangeDesignDocumentCategory = (e: React.ChangeEvent<HTMLSelectElement>, indexToUpdate: number) => {
    
        setDesignDocumentsState(
            designDocumentsState.map((doc, idx) => {
                if (idx !== indexToUpdate) return doc
                return {
                    ...doc, 
                    category: {
                        ...doc.category,
                        category: e.target.value
                    } 
                }
            })
        )
    }

    // TODO: Duplicated - clean up.
    const buildMetadata = (pageState: 'add' | 'edit' | 'view'): Metadata => {

        const PENDING_REVIEW: MetadataStatusType = 'PENDING_REVIEW'
        const APPROVED: MetadataStatusType = 'APPROVED'

        //const newStatus: MetadataStatusType = PAID_ADD_ONS.quarantineZone.isEnabled ? PENDING_REVIEW : APPROVED

        // APPROVED until we build quarantine zone for drawings.
        const newStatus: MetadataStatusType = APPROVED

        const timestamp = moment.utc().format(DATE_TIME_FORMAT)

        if (pageState === 'edit' && editObject) 
            return {
                ...editObject.metadata,
                status: newStatus,
                // if there is no created info (as products may have been created prior to the addition of metadata add it).
                ...(!editObject.metadata.created &&
                    { created: {
                        byUserId: loggedInUser.user_id,
                        byFullName: loggedInUser.name,
                        timestamp: timestamp
                    }}
                ),
                lastEdited: {
                    byUserId: loggedInUser.user_id,
                    byFullName: loggedInUser.name,
                    timestamp: timestamp
                }
            }
        else if (pageState === 'add') {

            return {
                status: newStatus,
                created: {
                    byUserId: loggedInUser.user_id,
                    byFullName: loggedInUser.name,
                    timestamp: timestamp
                },
                lastEdited: {
                    byUserId: loggedInUser.user_id,
                    byFullName: loggedInUser.name,
                    timestamp: timestamp
                }
            }
        } else null
        
    }

    const onSubmitForm = async (e: React.MouseEvent) => {
        e.preventDefault()

        const isFormValid: boolean = validateForm();

        if (isFormValid) {

            const metadata: Metadata = buildMetadata(pageState)
            const isSteelwork: boolean = formState.type.toLowerCase() === 'steelwork'

            setIsRequestLoading(true)

            const indexDoc: IndexDrawingDocument = {
                objectID: isSteelwork ? formState.drawingCode : formState.code,
                type: formState.type,
                code: formState.code,
                ...(isSteelwork && {drawingCode: formState.drawingCode}),
                description: formState.description,
                weight: formState.weight,
                tags: formState.tags,
                designDocuments: designDocumentsState.map((doc) => ({
                    ...doc,
                    category: doc.category.category,
                    asset: {
                        assetPath: doc.asset.filePath,
                        assetFileName: doc.asset.fileName,
                        assetFileExtension: doc.asset.fileExtension
                    } 
                })),
                ...(metadata && { metadata: metadata })
            }

            const accessToken: string = await getAccessTokenSilently()

            const response = await fetch(
                `${API_URL}/drawings/index`
                    , {
                    method: 'POST',
                    redirect: 'follow',
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                        "Content-Type": 'application/json'
                    },
                    body: JSON.stringify(indexDoc)
                }); 

            if (response.status === 201) {
                toast({
                    message: `Success, drawing ${pageState === 'edit' ? `edited` : `added`}.`,
                    type: 'is-link',
                    position: 'bottom-right',
                  })

                  setIsRequestLoading(false)
                  if (pageState !== 'edit') clearState()
                  
            } else {
                toast({
                    message: 'Oh no, something went wrong. Please try again.',
                    type: 'is-danger',
                    position: 'bottom-right',
                  })

                  setIsRequestLoading(false)
            }
        }
    }

    const validateForm = (): boolean => {

        const mustNotBeEmptyError: string = `Must not be empty`
        const mustSelectADrawingError: string = `You must select a drawing to upload.`
        

        const formErrors: FormErrorState = Object.entries(formState).reduce((acc, [key, value]) => {

            const isEmpty: boolean = value === null || value === '' || value === undefined
            const isEmptyArray: boolean = Array.isArray(value) && value.length === 0

            if (['tags', 'description', 'weight', 'drawingCode'].includes(key)) {
                return {
                    ...acc,
                    [key]: {
                        isError: false,
                        errorMessage: null
                    }
                }
            }

            return {
                ...acc,
                [key]: {
                    isError: isEmpty || isEmptyArray,
                    errorMessage: isEmpty || isEmptyArray ? mustNotBeEmptyError : null
                }
            }
        }, initialFormErrorState)

        setFormErrorState(formErrors)

        const designDocumentStateWithErrors: DesignDocumentFormState[] = designDocumentsState.map((doc) => ({
            ...doc,
            category: {
                ...doc.category,
                error: doc.category.category && doc.category.category !== '' ? null : mustNotBeEmptyError
            },
            asset: {
                ...doc.asset,
                error: doc.asset.filePath === null || doc.asset.filePath === undefined ? mustSelectADrawingError : null
            }
        }))

        setDesignDocumentsState(designDocumentStateWithErrors)

        return Object.entries(formErrors).every(([key, value])=> {
            const formError: FormInputError = value
            return formError.isError === false    

        }) && designDocumentStateWithErrors.every(_ => _.asset.error === null && _.category)
    }

    const clearState = () => {
        setFormState(initialFormState)
        setFormErrorState(initialFormErrorState)
        setDesignDocumentsState(initialDesignDocumentsState)
    }

    const onDeleteDrawing = async () => {

        const accessToken: string = await getAccessTokenSilently()

        setIsRequestLoading(true)

        try {
            const response = await fetch(
                `${API_URL}/drawings/get/${encodeURI(objectID)}`
                    , {
                    method: 'DELETE',
                    redirect: 'follow',
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                        "Content-Type": 'application/json'
                    },
                    body: ''
                }); 
    
            if (response.status === 200) {
                toast({
                    message: `Success, drawing deleted.`,
                    type: 'is-link',
                    position: 'bottom-right',
                  })

                  setIsRequestLoading(false)
                  setIsConfirmDeleteModalActive(false)
                  navigate({
                    pathname: '/',
                    search: '',
                  }, { replace: true })
                  
            } else {
                toast({
                    message: 'Oh no, something went wrong. Please try again.',
                    type: 'is-danger',
                    position: 'bottom-right',
                  })
            }
        } catch (error) {
            toast({
                message: 'Oh no, something went wrong. Please try again.',
                type: 'is-danger',
                position: 'bottom-right',
              })
        }

        setIsRequestLoading(false)
    }

    const onClear = (e: React.MouseEvent) => {
        e.preventDefault()
        clearState()
    }

    const onCancelEdit = (e: React.MouseEvent) => {
        e.preventDefault()
       initialiseEditObject(editObject)
    }

        // TODO: fix
        const removeAsset = (indexToRemove: number) => setDesignDocumentsState(
            designDocumentsState.map((doc, idx) => {
                if (idx !== indexToRemove) return doc
                return {
                    ...doc,
                    asset: initialAssetState
                }
            })
        )

        const onChangeFileInput = async (e: React.ChangeEvent<HTMLInputElement>, indexToUpdate: number) => {
            if (e.target.files.length > 0) {
                const file: File = Array.from(e.target.files)[0]
                const fileName: string = file.name.substring(0, file.name.lastIndexOf('.'))
                const fileExtension: string = file.name.substring(file.name.lastIndexOf('.') + 1)
    
                setDesignDocumentsState(
                    designDocumentsState.map((doc, idx) => {
                        if (idx !== indexToUpdate) return doc
                        return {
                            ...doc,
                            asset: {
                                ...doc.asset,
                                fileName: fileName, 
                                fileExtension: fileExtension, 
                                isLoading: true 
                            }
                        }
                    })
                )
    
                try {    
                    const accessToken: string = await getAccessTokenSilently()
    
                    // TODO - handle case this fails.
                    const getPresignedUrlResponse = await fetch(
                        `${API_URL}/get-presigned-url`
                            , {
                            method: 'POST',
                            redirect: 'follow',
                            headers: {
                                Authorization: `Bearer ${accessToken}`,
                                "Content-Type": 'application/json'
                            },
                            body: JSON.stringify({
                                fileName: fileName,
                                fileExtension: fileExtension
                            })
                        }); 
        
                    const getPresignedUrlResponseBody = await getPresignedUrlResponse.json()
        
                    const getUploadResponse = await fetch(getPresignedUrlResponseBody.uploadUrl, {
                        method: 'PUT',
                        headers: {
                            'Content-Type': file.type,
                        },
                        body: file
                    })
        
                    if (getUploadResponse.ok) {
    
                        setDesignDocumentsState(
                            designDocumentsState.map((doc, idx) => {
                                if (idx !== indexToUpdate) return doc
                                return {
                                    ...doc,
                                    asset: {
                                        ...doc.asset,
                                        fileName: fileName, 
                                        fileExtension: fileExtension, 
                                        filePath: getPresignedUrlResponseBody.assetPath,
                                        sourceUrl: getPresignedUrlResponseBody.retrievalUrl,
                                        isLoading: false,
                                        isUploaded: true,
                                    }
                                }
                            })
                        )

                        // required to reset the file input so that the event is fired of file name is the same.
                        e.target.value = null;
    
                    } else {
    
                        setDesignDocumentsState(
                            designDocumentsState.map((doc, idx) => {
                                if (idx !== indexToUpdate) return doc
                                return {
                                    ...doc,
                                    asset: {
                                        ...doc.asset,
                                        fileName: fileName, 
                                        fileExtension: fileExtension, 
                                        isLoading: true, 
                                        isUploaded: false, 
                                        error: `File upload failed. Please try again.`
                                    }
                                }
                            })
                        )
                
                    }
    
                } catch (error) {
    
                    setDesignDocumentsState(
                        designDocumentsState.map((doc, idx) => {
                            if (idx !== indexToUpdate) return doc
                            return {
                                ...doc,
                                asset: {
                                    ...doc.asset,
                                    fileName: fileName, 
                                    fileExtension: fileExtension, 
                                    isLoading: false,
                                    isUploaded: false, 
                                    error: `File upload failed. Please try again.`
                                }
                            }
                        })
                    )
                }
            }
        }

        const onCopyDrawingCode = () => {
            navigator.clipboard.writeText(formState.drawingCode)
        
            toast({
                message: `Copied to clipboard`,
                type: 'is-info',
                position: 'bottom-right',
                })
        }

    if ((pageState === 'edit' || pageState === 'view') && isEditObjectLoading) return(
        <div className="container is-fullheight">
            <div className="columns mt-6 pt-6">
                <div className="column is-offset-one-quarter is-half" style={{padding: '5rem', borderRadius: 6}}>
                    <PageLoadSpinner />
                </div>
            </div>
        </div>
    )

    if (pageState === 'view' && !editObject && !isEditObjectLoading) return(
        <div className="container is-fullheight">

            <div className="columns mt-6 pt-6">
                <div className="column is-offset-one-quarter is-half has-background-white p-6" style={{borderRadius: 6}}>

                    <div className="container has-text-centered">
                        <div className="columns is-vcentered">
                            <div className="column">
                                <img src={DrawingNotFoundImage} alt="JKH logo." width={'30%'} height={'30%'} />
                                <h1 className="title is-2 pt-3">This drawing doesn't exist.</h1>
                                <h2 className="subtitle is-4">Would you like to add one?</h2>
                                <Link to='/add/drawing'><button className="button is-link is-medium">Add drawing</button></Link>
                            </div>
                        </div>
                    </div>
                
                </div>
            </div>

        </div>
    )

    return (
        <div className="container is-fullheight">

            {pageState === 'edit' && Permissions.canDelete(loggedInUser) &&
                <ConfirmDeleteModal 
                    isModalActive={isConfirmDeleteModalActive}
                    isLoading={isRequestLoading}
                    onCancel={() => setIsConfirmDeleteModalActive(false)}
                    onConfirmDelete={onDeleteDrawing} />
            }

            <div className="columns mt-6 pt-6">

            <div className="column is-offset-one-quarter is-half has-background-white p-0" style={{borderRadius: 6}}>

                {pageState === 'edit' && Permissions.canDelete(loggedInUser) &&
                    <div className="dropdown is-hoverable pt-5 pr-5 is-pulled-right">
                        <div className="dropdown-trigger">
                            <FontAwesomeIcon aria-haspopup="true" aria-controls="dropdown-menu4" className="is-clickable" icon={faEllipsisVertical} size={`lg`} />
                        </div>
                        <div className="dropdown-menu" id="dropdown-menu4" role="menu">
                            <div className="dropdown-content">
                                <a href="#" className="dropdown-item" onClick={(e: React.MouseEvent) => { e.preventDefault(); setIsConfirmDeleteModalActive(true) } }>
                                    <span><FontAwesomeIcon icon={faTrash} className="pr-2" /> Delete</span>
                                </a>
                            </div>
                        </div>
                    </div>
                }
               
                <div className="block pt-6 pr-6 pl-6 p-2 mb-0">
                    <h1 className="title is-3">{pageState === 'edit'  ? `Edit Drawing` :  pageState === 'view' ? `View Drawing` : `Add a new drawing`}</h1>  
                </div>

                <div className="block p-6" style={{borderBottom: 'rgb(228, 228, 228) 1px solid'}}> 

                    <div className="field pb-1">
                        <label className="label">Type</label> 
                        <div className={`select ${formErrorState.type.isError ? `is-danger`: ''}`}>
                            <select disabled={pageState === 'view'} name="type" value={formState.type} onChange={onChangeInput}>
                                <option value={''} disabled={true} >Select a type</option>
                                {drawingTypes.map((dt, idx) => 
                                    <option key={`drawing-type-${idx}`} value={dt.value}>{dt.label}</option>
                                )}
                            </select>
                        </div>
                        {formErrorState.type.isError && 
                            <span className="help is-danger">{formErrorState.type.errorMessage}</span>
                        }
                    </div>

                    <InputField
                        fieldLabel={`Code`}
                        onChange={onChangeCode}
                        fieldName={'code'}
                        value={formState.code}
                        isDisabled={pageState === 'view' || pageState === 'edit'} 
                        errorValue={formErrorState.code}
                        helpText={`The code of the drawing`} />

                    {formState.type.toLowerCase() === 'steelwork' &&
                        <InputField
                            fieldLabel={`Drawing Code`}
                            fieldName={'drawingCode'}
                            onChange={() => {}}
                            value={formState.drawingCode}
                            errorValue={null}
                            helpText={`The drawing code`}
                            isDisabled={true} 
                            isCopyEnabled={formState.drawingCode ? true : false} 
                            onClickCopy={formState.drawingCode ? onCopyDrawingCode : null} />
                    }

                    <TextAreaInputField
                        fieldLabel={`Drawing Description`}
                        onChange={onChangeInput}
                        fieldName={'description'}
                        value={formState.description}
                        isDisabled={pageState === 'view'}
                        errorValue={formErrorState.description}
                        helpText={`The description of the drawing`} 
                        isOptional={true} />

                    <InputField
                        fieldLabel={`weight`}
                        onChange={onChangeInput}
                        fieldName={'weight'}
                        value={formState.weight}
                        errorValue={formErrorState.weight}
                        isDisabled={pageState === 'view'}
                        helpText={``}
                        isNumber={true}
                        hasAddOn={`kg`} />  

                    <TagsInput
                        fieldLabel={`Tags`}
                        fieldName={`tags`}
                        errorValue={formErrorState.tags}
                        tags={formState.tags}
                        isDisabled={pageState === 'view'}
                        onAddTag={(tag: string) => onAddTag('tags', tag)}
                        onDeleteTag={(tag: string) => onDeleteTag('tags', tag)}
                        isOptional={true} />
                </div>
                                
                <div className="block pt-4 pb-6 pl-6 pr-6" style={{borderBottom: 'rgb(228, 228, 228) 1px solid'}}>

                    <h2 className="subtitle is-5">{`Drawings`}</h2>   

                        {designDocumentsState && designDocumentsState.filter(_ => isMerchant(loggedInUser) ? _.category.category === 'ALTHON' : true).map((doc, idx) => (
                            <div className="columns is-vcentered" key={idx}>
                                <div className="column"> 
                                    <div className="field">
                                        <label className="label">Category</label> 
                                        <div className={`select ${doc.category.error ? `is-danger`: ''}`}>
                                            <select disabled={pageState === 'view'} name="category" value={doc.category.category} onChange={(e) => onChangeDesignDocumentCategory(e, idx)}>
                                                <option value="" disabled={true} >Select a category</option>
                                                {drawingCategories.map((dc, idx) => 
                                                    <option key={`drawing-code-${idx}`} value={dc.value}>{dc.label}</option>
                                                )}
                                            </select>
                                        </div>
                                        {doc && doc.category.error && 
                                            <span className="help is-danger">{doc.category.error}</span>
                                        }
                                    </div>
                                </div>

                                <div className="column pb-0">
                                    <div className="field">
                                    <label className="label">{pageState !== 'view' ? `Select a drawing` : `Drawing`}</label>
                                        {!(doc && doc.asset.fileName && doc.asset.fileExtension && pageState === 'view') &&
                                            <div className={`file pb-3`}>
                                                <label className="file-label" style={{...(doc && doc.asset.error && {border: 'hsl(348deg, 86%, 61%) 1px solid', borderRadius: '4px'} )}}>
                                                    <input onChange={(e) => onChangeFileInput(e, idx)} className={`file-input`} type="file" name="drawing" />
                                                    <span className="file-cta">
                                                        <span className="file-icon">
                                                            <FontAwesomeIcon icon={faFileCirclePlus} />
                                                        </span>
                                                        <span className="file-label">
                                                            Choose a file…
                                                        </span>
                                                    </span>
                                                </label>
                                            </div>
                                        }
                                        {doc && doc.asset.fileName && doc.asset.fileExtension &&
                                            <>
                                            <a className="is-underlined is-size-6" target="_blank" href={doc.asset.sourceUrl}>{doc.asset.fileName + '.' +  doc.asset.fileExtension} </a>
                                            {doc.asset.isLoading 
                                                ? <FontAwesomeIcon className="ml-2 is-clickable" icon={faCircleNotch} spin={true} />
                                                : doc.asset.isUploaded 
                                                ? pageState !== 'view' && <FontAwesomeIcon className="ml-2 is-clickable" icon={faXmark} onClick={() => removeAsset(idx)} />
                                                : null
                                            }
                                            </>
                                        }
                                        {doc && doc.asset.error && 
                                            <span className="help is-danger">{doc.asset.error}</span>
                                        }
                                    </div>
                                </div>
                                
                                {pageState !== 'view' &&
                                    <div className="column is-narrow">
                                        {designDocumentsState.length > 1 &&
                                            <span className="is-clickable" onClick={(e: React.MouseEvent) => onDeleteDesignDocument(e, idx)}>{<FontAwesomeIcon icon={faXmark} />}</span>
                                        }
                                    </div>
                                }
                            </div>
                        ))}

                        {pageState !== 'view' &&
                            <div className="field">
                                <AddAnotherBtn onClick={onAddDesignDocument} /> 
                            </div>
                        }
                    </div>

                    <div className="block pt-4 pb-6 pl-6 pr-6 mb-4">
                        {pageState !== 'view' &&
                            <div className="field is-grouped is-pulled-right">
                                <div className="control">
                                    <button className={`button is-link ${isRequestLoading ? `is-loading` : ``}`} disabled={designDocumentsState && designDocumentsState.every(_ => _.asset.isLoading)} onClick={onSubmitForm}>{pageState === 'edit' ? `Edit` : `Submit`}</button>
                                </div>
                                <div className="control">
                                    {pageState === 'edit'  ?
                                        <button className="button is-link is-light" onClick={onCancelEdit}>Cancel</button>
                                    :
                                        <button className="button is-link is-light" onClick={onClear}>Clear</button>   
                                    }
                                </div>
                            </div>
                        }
                    </div>
         
                </div>

            </div>                    

        </div>
    )
}

export { IndexDrawingScreen }