import React, { useState } from 'react'

import { ActionMeta, MultiValue, OnChangeValue } from 'react-select'
import { v4 as uuidv4 } from 'uuid'
import { customAlphabet } from 'nanoid'
import isEqual from 'lodash.isequal'

import { PipeOpeningConfig } from 'pages/settings/configuration/ManagePipeOpeningsScreen'
import { ProductSearchResult } from 'models/SearchResult'

export interface FlowControlDevice {
    size: number,
    type: string,
    manufacturer?: string
  }

const initialFlowControlDevice: FlowControlDevice = {
    size: 0,
    type: ''
}

interface PipeOpeningFormState {
    pipeId: string,
    pipeType?: 'CULVERT' | 'CUSTOM' | 'PREDEFINED'
    insideDiameter?: number,
	material?: string,
	outsideDiameter?: number, 
	openingSize?: number,
    invertLevel?: number,
    openingLevel?: number,
    width?: number,  // used only for CULVERT pipe
    height?: number, // used only for CULVERT pipe
}

const initialPipeOpening: PipeOpeningFormState = {
    pipeId: '',
    pipeType: 'PREDEFINED',
    insideDiameter: 0,
	material: '',
	outsideDiameter: 0, 
	openingSize: 0,
    invertLevel: 0,
    openingLevel: 0,
    width: 0,
    height: 0
}

interface ProductFormState {
    productCode: string,
    description: string,
    productType: string,
    flowControlDevices: FlowControlDevice[],
    secondaryCasting: string[],
    reinforcing: string[],
    toe: string,
    steelworkCode: string[],
    handrail: string,
    pipeOpenings: PipeOpeningFormState[],
    tags: string[]
}

const initialFormState: ProductFormState = {
    productCode: '',
    description: '',
    productType: '',
    flowControlDevices: [ initialFlowControlDevice ],
    secondaryCasting: [],
    reinforcing:[],
    toe: '',
    steelworkCode: [],
    handrail: '',
    pipeOpenings: [ initialPipeOpening ],
    tags: [] 
}

const initialFormInputErrorState: FormInputError = {
    isError: false,
    errorMessage: null
}

export interface FormInputError {
    isError: boolean,
    errorMessage: string
}

interface PipeOpeningsErrorState {
    pipeId: FormInputError,
    invertLevel: FormInputError,
    openingLevel: FormInputError,
    width: FormInputError,
    height: FormInputError
}

const initialPipeOpeningErrorState = {
    pipeId: initialFormInputErrorState,
    invertLevel: initialFormInputErrorState,
    openingLevel: initialFormInputErrorState,
    width: initialFormInputErrorState,
    height: initialFormInputErrorState,
}

interface FlowControlDevicesErrorState {
    size: FormInputError,
    type: FormInputError,
    manufacturer: FormInputError
}

interface ProductFormErrorState {
    description: FormInputError,
    productType: FormInputError,
    flowControlDevices: FlowControlDevicesErrorState[],
    secondaryCasting: FormInputError,
    reinforcing: FormInputError,
    toe: FormInputError,
    steelworkCode: FormInputError,
    handrail: FormInputError,
    pipeOpenings: PipeOpeningsErrorState[],
    tags: FormInputError
}

const initialFormErrorState: ProductFormErrorState = {
    description: initialFormInputErrorState,
    productType: initialFormInputErrorState,
    flowControlDevices: [{
        size:initialFormInputErrorState,
        type: initialFormInputErrorState,
        manufacturer: initialFormInputErrorState,
    }],
    secondaryCasting: initialFormInputErrorState,
    reinforcing: initialFormInputErrorState,
    toe: initialFormInputErrorState,
    steelworkCode: initialFormInputErrorState,
    handrail: initialFormInputErrorState,
    pipeOpenings: [initialPipeOpeningErrorState],
    tags: initialFormInputErrorState
}

export interface IUseProductForm {
    formState: ProductFormState,
    loadFormState: (productToLoad: ProductSearchResult) => void,
    formErrorState: ProductFormErrorState,
    initialFlowControlDevice: FlowControlDevice,
    initialPipeOpening: PipeOpeningFormState,
    resetFormState: () => void,
    validateForm: () => boolean,
    onChangeInput: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => void,
    onChangeObjectInArray: (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>, formAttributeName: keyof Pick<ProductFormState, 'pipeOpenings' | 'flowControlDevices'>, indexToUpdate: number) => void,
    onAddObjectToArray: (e: React.MouseEvent, formAttributeName: keyof Pick<ProductFormState, 'pipeOpenings' | 'flowControlDevices'>, initialObj: PipeOpeningFormState | FlowControlDevice) => void,
    onDeleteFromArray: (e: React.MouseEvent, indexToRemove: number, formAttributeName: keyof Pick<ProductFormState, 'pipeOpenings' | 'flowControlDevices'>) => void,
    onChangeOpeningLevel: (e: React.ChangeEvent<HTMLInputElement>, indexToUpdate: number) => void,
    onChangePipe: (e: React.ChangeEvent<HTMLSelectElement>, indexToUpdate: number, pipeOpenings: PipeOpeningConfig[]) => void,
    onChangeHeight: (e: React.ChangeEvent<HTMLInputElement>, indexToUpdate: number) => void,
    onChangeWidth: (e: React.ChangeEvent<HTMLInputElement>, indexToUpdate: number) => void,
    onChangeProductType: (newValue: OnChangeValue<{value: string, label: string} | null, false>, actionMeta: ActionMeta<{value: string, label: string}>) => void,
    onChangeSteelworkCode: (newValue: MultiValue<{ value: string, label: string }>, actionMeta: ActionMeta<{ value: string, label: string }>) => void,
    onChangeSecondaryCasting: (newValue: MultiValue<{ value: string, label: string }>, actionMeta: ActionMeta<{ value: string, label: string }>) => void,
    onChangeReinforcing: (newValue: MultiValue<{ value: string, label: string }>, actionMeta: ActionMeta<{ value: string, label: string }>) => void,
    onAddTag: (fieldName: keyof Pick<ProductFormState, 'tags'>, value: string) => void,
    onDeleteTag: (fieldName: keyof Pick<ProductFormState, 'tags'>, value: string) => void,
    onChangeFlowControlDevice: (e: React.ChangeEvent<HTMLSelectElement>, indexToUpdate: number) => void,
    computePipeClearance: (openingSize: number,outsideDiameter: number) => number,
    buildProductCode: (productType: string) => string,
    isCulvertPipe: (pipeType: string) => boolean,
}

export const useProductForm = (): IUseProductForm => {

    const loadFormState = (productToLoad: ProductSearchResult) => {
        setFormState({
            ...productToLoad, 
            ...(productToLoad.pipeOpenings.length === 0 && { pipeOpenings: [ initialPipeOpening ] }),
            ...(productToLoad.flowControlDevices.length === 0 && { flowControlDevices: [ initialFlowControlDevice ] }),
            ...(productToLoad.reinforcing?.length === 0 && {reinforcing: []})
        })
    }

    // TODO -create hook - useGeneratedID
    const generateId = (): string => {
        const nanoid = customAlphabet('0123456789', 10)
        return nanoid(6)
    }

    const generatedId: string = generateId()

    const [formState, setFormState] = useState<ProductFormState>(initialFormState)
    const [formErrorState, setFormErrorState] = useState<ProductFormErrorState>(initialFormErrorState)

    /**
     * Resets form to empty state. 
     */
    const resetFormState = () =>  {
        setFormState(initialFormState)
        setFormErrorState(initialFormErrorState)
    }

    const validateForm = (): boolean => {

        const mustNotBeEmptyError: string = `Must not be empty`
        const mustBeGreaterThanZero: string = `Must be greater than zero`
        const mustHaveCorrectPipePositionError = (pipePosition: number) => `There must be a difference of ${pipePosition}mm between opening size and inside diameter.` 

        const formErrors: ProductFormErrorState = Object.entries(formState).reduce((acc, [key, value]) => {


            if (key === 'pipeOpenings') {
                const pipeOpeningsFormState: PipeOpeningFormState[] = value
                return {
                    ...acc,
                    pipeOpenings: [...pipeOpeningsFormState.map((formState) => {
                        if (isEqual(formState, initialPipeOpening)) {
                            return initialPipeOpeningErrorState
                        } else if (isCulvertPipe(formState.pipeType)) {
    
                            return {
                                pipeId: {
                                    isError: formState.pipeId === null || formState.pipeId === '',
                                    errorMessage: formState.pipeId === null || formState.pipeId === '' ? mustNotBeEmptyError : null
                                },
                                width: {
                                    isError: formState.width <= 0,
                                    errorMessage:formState.width <= 0 ? mustBeGreaterThanZero : null
                                },
                                height: {
                                    isError: formState.height <= 0,
                                    errorMessage: formState.height <= 0 ? mustBeGreaterThanZero : null
                                },
                                invertLevel: initialFormInputErrorState, // we don't care for a culvert pipe
                                openingLevel: initialFormInputErrorState, // we don't care for a culvert pipe
                            }

                        } else {
                            const expectedPipePosition: number = computePipePosition(formState.openingSize, formState.insideDiameter)
                            const isPipePositionValid: boolean = (formState.invertLevel - formState.openingLevel) === expectedPipePosition

                            return {
                                pipeId: {
                                    isError: formState.pipeId === null || formState.pipeId === '',
                                    errorMessage: formState.pipeId === null || formState.pipeId === '' ? mustNotBeEmptyError : null
                                },
                                invertLevel: {
                                    isError: !isPipePositionValid,
                                    errorMessage: null
                                },
                                openingLevel: {
                                    isError: !isPipePositionValid,
                                    errorMessage: !isPipePositionValid ? mustHaveCorrectPipePositionError(expectedPipePosition) : null
                                },
                                width: initialFormInputErrorState, // we don't care for a non culvert pipe
                                height: initialFormInputErrorState, // we don't care for a non culvert pipe
                            }

                        }
                    })]
                }
            }
            
            if (key === 'flowControlDevices') {
                const flowControlDevices: FlowControlDevice[] = value
                return {
                    ...acc,
                    flowControlDevices: [...flowControlDevices.map((fcd) => {
                        if (isEqual(fcd, initialFlowControlDevice)) {
                            return {
                                size: initialFormInputErrorState,
                                type: initialFormInputErrorState,
                                manufacturer: initialFormInputErrorState
                            }
                        } else {
                        return {
                            size: {
                                isError: fcd.size <= 0,
                                errorMessage: fcd.size <= 0 ? mustBeGreaterThanZero : null
                            },
                            type: {
                                isError: fcd.type === null || fcd.type === '',
                                errorMessage: fcd.type === null || fcd.type === '' ? mustNotBeEmptyError : null
                            },
                            manufacturer: {
                                isError: false,
                                errorMessage: null,
                            }
                        }
                    }})]
                }
            }

            if (['secondaryCasting', 'steelworkCode', 'reinforcing', 'tags', 'description', 'toe', 'handrail'].includes(key)) {
                return {
                    ...acc,
                    [key]: {
                        isError: false,
                        errorMessage: null
                    }
                }
            }
            
            return {
                ...acc,
                [key]: {
                    isError: value === null || value === '' || (Array.isArray(value) && value.length === 0),
                    errorMessage: value === null || value === '' || (Array.isArray(value) && value.length === 0) ? mustNotBeEmptyError : null
                }
            }
        }, initialFormErrorState)

        setFormErrorState(formErrors)

        return Object.entries(formErrors).every(([key, value]) => {
            if (key === 'pipeOpenings') {
                const pipeOpeningErrors: PipeOpeningsErrorState[] = value
                return pipeOpeningErrors.every(_ => _.invertLevel.isError === false && _.pipeId.isError === false && _.openingLevel.isError === false)
            }

            if (key === 'flowControlDevices') {
                const flowControlDeviceErrors: FlowControlDevicesErrorState[] = value
                return flowControlDeviceErrors.every(_ => _.size.isError === false && _.type.isError === false)
            }

            const formError: FormInputError = value
            return formError.isError === false    

        })
    }

    const onChangeInput = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {

        setFormState({
            ...formState,
            [e.target.name]: e.target.value
        })
    }

    const onChangeObjectInArray = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>, formAttributeName: keyof Pick<ProductFormState, 'pipeOpenings' | 'flowControlDevices'>, indexToUpdate: number) => {

        setFormState({
            ...formState,
            [formAttributeName]: formState[formAttributeName].map((obj, idx) => {
                if (idx !== indexToUpdate) return obj
                return {...obj, [e.target.name]: e.target.value }    
            })
        })
    }

    const onAddObjectToArray = (e: React.MouseEvent, formAttributeName: keyof Pick<ProductFormState, 'pipeOpenings' | 'flowControlDevices'>, initialObj: PipeOpeningFormState | FlowControlDevice) => {
        e.preventDefault()
    
        setFormState({
            ...formState,
            [formAttributeName]: [...formState[formAttributeName], initialObj]
        })
    }

    const onDeleteFromArray = (e: React.MouseEvent, indexToRemove: number, formAttributeName: keyof Pick<ProductFormState, 'pipeOpenings' | 'flowControlDevices'>) => {
        e.preventDefault()

        const toModify: any[] = formState[formAttributeName]
    
        setFormState({
            ...formState,
            [formAttributeName]: toModify.filter((_, idx) => idx != indexToRemove)
        })
    }

    const computePipeClearance = (openingSize: number,outsideDiameter: number) : number => (openingSize - outsideDiameter) / 2

    const computePipePosition = (openingSize: number,insideDiameter: number) : number => (openingSize - insideDiameter) / 2

    const onChangeOpeningLevel = (e: React.ChangeEvent<HTMLInputElement>, indexToUpdate: number) => {

            const openingLevel: number = parseInt(e.target.value)
        
            setFormState({
                ...formState,
                pipeOpenings: formState.pipeOpenings.map((obj, idx) => {
                    if (idx !== indexToUpdate) return obj
                    return {
                        ...obj,
                        openingLevel: openingLevel,
                        invertLevel: openingLevel + computePipePosition(obj.openingSize, obj.insideDiameter),
                    }    
                })
            })
    
    }

    const onChangeHeight = (e: React.ChangeEvent<HTMLInputElement>, indexToUpdate: number) => {
    
        setFormState({
            ...formState,
            pipeOpenings: formState.pipeOpenings.map((obj, idx) => {
                if (idx !== indexToUpdate) return obj
                return {
                    ...obj,
                    height: parseInt(e.target.value),
                }    
            })
        })
    }

    const onChangeWidth = (e: React.ChangeEvent<HTMLInputElement>, indexToUpdate: number) => {
    
        setFormState({
            ...formState,
            pipeOpenings: formState.pipeOpenings.map((obj, idx) => {
                if (idx !== indexToUpdate) return obj
                return {
                    ...obj,
                    width: parseInt(e.target.value),
                }    
            })
        })
    }

    const onChangePipe = (e: React.ChangeEvent<HTMLSelectElement>, indexToUpdate: number, pipeOpenings: PipeOpeningConfig[]) => {

        const pipeId = e.target.value
        const isCulvert: boolean = isCulvertPipe(pipeId) // Note: in case of culvert pipe selected value is passed as CULVERT
        const isNoPipe: boolean = pipeId === ''
       
        if (isCulvert) {

            setFormState({
                ...formState,
                pipeOpenings: formState.pipeOpenings.map((obj, idx) => {
                    if (idx !== indexToUpdate) return obj
                    return {
                        pipeId: uuidv4(),
                        pipeType: 'CULVERT',
                        material: 'GENERIC_CONCRETE',
                        width: 0,
                        height: 0
                    }    
                })
            })

        } else if (isNoPipe) {

            setFormState({
                ...formState,
                pipeOpenings: formState.pipeOpenings.map((obj, idx) => {
                    if (idx !== indexToUpdate) return obj
                    return initialPipeOpening    
                })
            })

        } else {

            const allInfo: PipeOpeningConfig | undefined = pipeOpenings.find(_ => _.id === pipeId)
            const { id, isDeleted, ...poConfig} = allInfo

            setFormState({
                ...formState,
                pipeOpenings: formState.pipeOpenings.map((obj, idx) => {
                    if (idx !== indexToUpdate) return obj
                    return {
                        pipeId: pipeId,
                        pipeType: 'PREDEFINED',
                        insideDiameter: poConfig.insideDiameter,
                        outsideDiameter: poConfig.outsideDiameter,
                        openingSize: poConfig.openingSize,
                        material: poConfig.material,
                        openingLevel: 0,
                        invertLevel: 0
                    }    
                })
            })

        }

    }

    const onChangeFlowControlDevice = (e: React.ChangeEvent<HTMLSelectElement>, indexToUpdate: number) => {

        const fcdType: string = e.target.value
        const isNoDevice: boolean = fcdType === ''

        if (isNoDevice) {

            setFormState({
                ...formState,
                ['flowControlDevices']: formState['flowControlDevices'].map((obj, idx) => {
                    if (idx !== indexToUpdate) return obj
                    return initialFlowControlDevice 
                })
            })

        } else onChangeObjectInArray(e, 'flowControlDevices', indexToUpdate)  
    }

    const buildProductCode = (productType: string): string => `${productType}-${generatedId}`

    const isCulvertPipe = (pipeType: string) => pipeType === 'CULVERT'

    const onChangeProductType = (newValue: OnChangeValue<{value: string, label: string} | null, false>, actionMeta: ActionMeta<{value: string, label: string}>) => {

        setFormState({
            ...formState,
            productType: newValue ? newValue.value : '',
            productCode: newValue && newValue.value ? buildProductCode(newValue.value) : ''
        })
    }

    const onChangeSteelworkCode = (newValue: MultiValue<{ value: string, label: string }>, actionMeta: ActionMeta<{ value: string, label: string }>) => {

        setFormState({
            ...formState,
            steelworkCode: newValue.map(_ => _.value)
        })
    }

    const onChangeSecondaryCasting = (newValue: MultiValue<{ value: string, label: string }>, actionMeta: ActionMeta<{ value: string, label: string }>) => {

        setFormState({
            ...formState,
            secondaryCasting: newValue.map(_ => _.value)
        })
    }

    const onChangeReinforcing = (newValue: MultiValue<{ value: string, label: string }>, actionMeta: ActionMeta<{ value: string, label: string }>) => {

        setFormState({
            ...formState,
            reinforcing: newValue.map(_ => _.value)
        })
    }

    const onAddTag = (fieldName: keyof Pick<ProductFormState, 'tags'>, value: string) => {
    
        setFormState({ 
            ...formState,
            [fieldName]: [...formState[fieldName], value]
        })
    }

    const onDeleteTag = (fieldName: keyof Pick<ProductFormState, 'tags'>, value: string) => {
    
        setFormState({
            ...formState,
            [fieldName]: formState[fieldName].filter((current) => current != value )
        })
    }

    return {
        formState,
        loadFormState,
        formErrorState,
        initialFlowControlDevice,
        initialPipeOpening,
        resetFormState,
        validateForm,
        onChangeInput,
        onChangeObjectInArray,
        onAddObjectToArray,
        onDeleteFromArray,
        onChangeOpeningLevel,
        onChangePipe,
        onChangeHeight,
        onChangeWidth,
        onChangeProductType,
        onChangeSteelworkCode,
        onChangeSecondaryCasting,
        onChangeReinforcing,
        onAddTag,
        onDeleteTag,
        onChangeFlowControlDevice,
        computePipeClearance,
        buildProductCode,
        isCulvertPipe
    }
    
}