import React, { ReactElement, useState } from 'react'

import InputField from 'components/inputs/InputField'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { v4 as uuidv4 } from 'uuid'
import { toast } from 'bulma-toast'
import { faAdd, faChevronDown, faChevronUp, faCircleInfo, faWarning, faXmark } from '@fortawesome/free-solid-svg-icons'
import { handrailCodes, flowControlDeviceTypes, toeBeamCodes, ConfigValueItem, prettyDisplayValue } from 'constants/Constants'
import { TagsInput } from 'components/inputs/TagsInput'
import { TextAreaInputField } from 'components/inputs/TextAreaInputField'
import { AddAnotherBtn } from 'components/buttons/AddAnotherBtn'
import Select, { createFilter, components, GroupBase } from 'react-select'

import { OptionalFieldFormLabel } from 'components/inputs/labels/OptionalFieldFormLabel'
import { IUseProductForm } from 'hooks/useProductForm'
import { DesignDocumentsForm } from './DesignDocumentsForm'
import { IUseDesignDocumentsForm } from 'hooks/useDesignDocumentsForm'
import { PipeOpeningConfig } from 'pages/settings/configuration/ManagePipeOpeningsScreen'
import { BaseModal } from 'components/modals/BaseModal'
import { FormInputError } from 'IndexProductScreen'
import { DrawingSearchResult } from 'models/SearchResult'
import { IDrawingSearchResponse } from 'models/SearchResponse'
import { Link } from 'react-router-dom'
import { useDrawings } from 'hooks/useDrawings'
import { AsyncPaginate } from "react-select-async-paginate";
import type { LoadOptions } from "react-select-async-paginate";



const CollapsibleFormSection = ({
    headerTitle, 
    body,
    isOptional = false,
    isCollapsedByDefault = false,
    isCollapsible = true,
}:{
    headerTitle: string,
    body: ReactElement,
    isOptional?: boolean,
    isCollapsedByDefault?: boolean,
    isCollapsible?: boolean
}) => {

    const [isCollapsed, setIsCollapsed] = useState<boolean>(isCollapsedByDefault)

    return (
        <div className="block pt-4 pb-6 pl-6 pr-6" style={{borderBottom: 'rgb(228, 228, 228) 1px solid'}}>

            <h2 className="subtitle is-5">
                {headerTitle} {isOptional && <OptionalFieldFormLabel />} 
                {isCollapsible
                ?
                    isCollapsed
                    ? 
                    <FontAwesomeIcon className="is-clickable is-pulled-right" onClick={() => setIsCollapsed(!isCollapsed)} icon={faChevronDown} /> 
                    : 
                    <FontAwesomeIcon className="is-clickable is-pulled-right" onClick={() => setIsCollapsed(!isCollapsed)}  icon={faChevronUp} />
               :
               null
            } 
            </h2>

            {!isCollapsed
            ?
            <div className="block">
                {body}
            </div>
            :
            null
            }
    
    </div>
    )
}

// TODO - duplicate of ManagePipeOpenings
type CustomPipeOpeningFormState = {
    insideDiameter: number,
	material: string,
	outsideDiameter: number, 
	openingSize: number,
}

// TODO - duplicate of ManagePipeOpenings
const initialCustomPipeOpeningFormState: CustomPipeOpeningFormState = {
    insideDiameter: 0,
	material: '',
	outsideDiameter: 0, 
	openingSize: 0,
}

// TODO - duplicate of ManagePipeOpenings
const initialFormInputErrorState: FormInputError = {
    isError: false,
    errorMessage: null
}

// TODO - duplicate of ManagePipeOpenings
interface CustomPipeOpeningFormErrorState {
    insideDiameter: FormInputError,
    material: FormInputError,
    outsideDiameter: FormInputError,
    openingSize: FormInputError,
}

// TODO - duplicate of ManagePipeOpenings
const initialCustomPipeOpeningFormErrorState: CustomPipeOpeningFormErrorState = {
    insideDiameter: initialFormInputErrorState,
	material: initialFormInputErrorState,
	outsideDiameter: initialFormInputErrorState, 
	openingSize: initialFormInputErrorState,
}

const ProductForm = ( { 
    isMerchant, 
    formView, 
    productForm, 
    designDocumentsForm,
    productTypes,
    pipeOpenings,
    pipeMaterials,
} : { 
    isMerchant: boolean, 
    formView: 'add' | 'edit' | 'view', 
    productForm: IUseProductForm, 
    designDocumentsForm: IUseDesignDocumentsForm,
    productTypes: ConfigValueItem[],
    pipeOpenings: PipeOpeningConfig[]
    pipeMaterials: ConfigValueItem[]
} ) => {

    const { searchDrawingsApi } = useDrawings()

    type OptionType = {
        value: string;
        label: string;
      };

    type AdditionalType = {
        page: number;
    };
      
    const defaultAdditional: AdditionalType = {
        page: 0
    };
   
const loadDrawing = async (drawingType: string, q: string, page: number, transformFn: (_: DrawingSearchResult) => OptionType) => {

    const response =  await searchDrawingsApi(q, {type: { appliedFilters: [drawingType]} }, page)
    const searchResp = await response.json() as IDrawingSearchResponse

    return {
        options: searchResp.results.map((d) => (transformFn(d))),
        hasMore: searchResp.page < searchResp.pages,
        additional: {
            page: page + 1
        }
    };
}

const loadOptionsSteelwork: LoadOptions<
  OptionType,
  GroupBase<OptionType>,
  AdditionalType
> = async (q, prevOptions, { page }) => {

    return loadDrawing("STEELWORK", q, page, (_: DrawingSearchResult) => ({
        value: _.drawingCode,
        label: _.drawingCode
    }))

}

const loadOptionsSecondaryCasting: LoadOptions<
  OptionType,
  GroupBase<OptionType>,
  AdditionalType
> = async (q, prevOptions, { page }) => {
    
    return loadDrawing("SECONDARY_CASTING", q, page, (_: DrawingSearchResult) => ({
        value: _.code,
        label: _.code
    }))

}

const loadOptionsReinforcing: LoadOptions<
  OptionType,
  GroupBase<OptionType>,
  AdditionalType
> = async (q, prevOptions, { page }) => {
    
    return loadDrawing("REINFORCING", q, page, (_: DrawingSearchResult) => ({
        value: _.code,
        label: _.code
    }))

}

    // @ts-ignore
    const MultiValueLabel = (props) => {
            return (
                <Link className='is-underlined' to={`/view/drawing/${props.data.value}`} target='_blank'>
                    <components.MultiValueLabel {...props} />
                </Link>
            );
      };
      
    // TODO - duplicate of ManagePipeOpenings
    const [isAddCustomPipeOpeningModalActive, setIsAddCustomPipeOpeningModalActive] = useState<boolean>(false)
    const [customPipeOpeningFormState, setCustomPipeOpeningFormState] = useState<CustomPipeOpeningFormState>(initialCustomPipeOpeningFormState)
    const [customPipeOpeningFormErrorState, setCustomPipeOpeningFormErrorState] = useState<CustomPipeOpeningFormErrorState>(initialCustomPipeOpeningFormErrorState)

    const { 
        formState,
        formErrorState,
        initialFlowControlDevice,
        initialPipeOpening,
        onChangeInput,
        onChangeObjectInArray,
        onAddObjectToArray,
        onDeleteFromArray,
        onChangeOpeningLevel,
        onChangePipe,
        onChangeWidth,
        onChangeHeight,
        onChangeProductType,
        onChangeSteelworkCode,
        onChangeSecondaryCasting,
        onChangeReinforcing,
        onAddTag,
        onDeleteTag,
        onChangeFlowControlDevice,
        computePipeClearance,
        isCulvertPipe,
    } = productForm

    const displayNoneIfEmptyAndInViewState = <T, >(someArray: T[]) => {
        if(someArray.length === 0 && formView === 'view') {
            return (<span className="is-italic">None</span>)
        }
        return null
    } 

    const onCopyProductCode = () => {
        navigator.clipboard.writeText(formState.productCode)

        toast({
            message: `Copied to clipboard`,
            type: 'is-info',
            position: 'bottom-right',
          })
    }

    // TODO - duplicate of ManagePipeOpenings
    const onChangeCustomPipeOpeningFormState = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
        setCustomPipeOpeningFormState({
            ...customPipeOpeningFormState,
            [e.target.name]: typeof e.target.value === 'number' ? parseInt(e.target.value) : e.target.value
        })
    }

    // TODO - duplicate of ManagePipeOpenings
    const onCancelModal = (e: React.MouseEvent) => {
        e.preventDefault()
        setCustomPipeOpeningFormState(initialCustomPipeOpeningFormState)
        setCustomPipeOpeningFormErrorState(initialCustomPipeOpeningFormErrorState)
        setIsAddCustomPipeOpeningModalActive(false)
    }

    // TODO: de-dupe with same form in ManagePipeOpenings page.
    const validateCustomPipeOpeningForm = (): boolean => {

        const mustNotBeEmptyError: string = `Must not be empty`
        const mustBeGreaterThanZero: string = `Must be greater than zero`

        const formErrors: CustomPipeOpeningFormErrorState = Object.entries(customPipeOpeningFormState).reduce((acc, [key, value]) => {
            
            return {
                ...acc,
                [key]: {
                    isError: value === null || value === '' || (!isNaN(parseInt(value.toString())) && parseInt(value.toString()) <= 0),
                    errorMessage: value === null || value === '' ? mustNotBeEmptyError : (!isNaN(parseInt(value.toString())) && parseInt(value.toString()) <= 0)  ? mustBeGreaterThanZero : null,
                }
            }
        }, initialCustomPipeOpeningFormErrorState)

        setCustomPipeOpeningFormErrorState(formErrors)

        return Object.entries(formErrors).every(([key, value])=> {
            const formError: FormInputError = value
            return formError.isError === false
        })
    }

    const onSubmitCustomPipeForm = (e: React.MouseEvent) => {
        e.preventDefault()
    
        const isFormValid: boolean = validateCustomPipeOpeningForm();
    
        if (isFormValid) {

            onAddObjectToArray(e, 'pipeOpenings', {
                pipeId: uuidv4(),
                pipeType: 'CUSTOM',
                material: customPipeOpeningFormState.material,
                insideDiameter: customPipeOpeningFormState.insideDiameter,
                openingSize: customPipeOpeningFormState.openingSize,
                outsideDiameter: customPipeOpeningFormState.outsideDiameter,
                invertLevel: 0,
                openingLevel: 0,
        
            })

            onCancelModal(e)        
        }
    }

    const displayProductTypesGrouped = () => {

        type ProductTypesGrouped = {
            H: ConfigValueItem[],
            SP: ConfigValueItem[],
            SFA: ConfigValueItem[],
            RSFA: ConfigValueItem[],
            AH: ConfigValueItem[],
            Swale: ConfigValueItem[],
            Unknown: ConfigValueItem[],
        }

        type ProductTypeLabels = {
            H: string,
            SP: string,
            SFA: string,
            RSFA: string,
            AH: string,
            Swale: string,
            Unknown: string
        }

        const productTypeLabelsMap: ProductTypeLabels = {
            H: 'Standard Headwall',
            SP: 'SP Spec Headwall',
            SFA: 'SFA Spec Headwall',
            RSFA: 'RSFA Spec Headwall',
            AH: 'Angled Headwall',
            Swale: 'Swale Headwall',
            Unknown: 'Unknown'
        }

        const addItemToGroup = (acc: ProductTypesGrouped, item: ConfigValueItem, groupKey: keyof ProductTypesGrouped) => (
            {
                ...acc,
                [groupKey]: [...acc[groupKey] || [], item]
            }
        )

        const grouped = productTypes
            .filter(_ => !_.isDeleted)
            .reduce((acc, item) => {
                if (item.label.startsWith('H') || item.label.startsWith('1PHDST') || item.label.startsWith('2PHDST')) {
                    return addItemToGroup(acc, item, 'H')   
                    
                } else if (item.label.startsWith('SP')) {
                    return addItemToGroup(acc, item, 'SP')
                    
                } else if (item.label.startsWith('SFA') || item.label.startsWith('N')) {
                    return addItemToGroup(acc, item, 'SFA')
                    
                } else if (item.label.startsWith('RSFA') || item.label.startsWith('R')) {
                    return addItemToGroup(acc, item, 'RSFA')
                    
                } else if (item.label.startsWith('AH')) {
                    return addItemToGroup(acc, item, 'AH') 

                } else if (item.label.toLowerCase().includes('swale')) {
                    return addItemToGroup(acc, item, 'Swale') 
                }

                return addItemToGroup(acc, item, 'Unknown')
                
            }, {} as ProductTypesGrouped)

        return Object.entries(grouped).map(([type, list]) => {

            const keyedType: keyof ProductTypeLabels = type as keyof ProductTypeLabels

            return {
                label: productTypeLabelsMap[keyedType],
                options: list
            }
        }).sort((a,b) => a.label.localeCompare(b.label))
    }

    return (
        <>
        {/* TODO - duplicate of ManagePipeOpenings */}
        <BaseModal
            isModalActive={isAddCustomPipeOpeningModalActive}
            modalTitle={`Add a Custom Pipe Opening`}
            onCancelModal={onCancelModal}>
                <form>
                    <div className="field">
                        <label className="label">Material</label> 
                        <div className={`select is-fullwidth ${customPipeOpeningFormErrorState.material && customPipeOpeningFormErrorState.material.isError ? `is-danger`: ''}`}>
                            <select name="material" value={customPipeOpeningFormState.material} onChange={onChangeCustomPipeOpeningFormState}>
                                <option value="" disabled={true} >Select a material</option>
                                {/* TODO - clear this up once we handle deletes properly */}
                                {pipeMaterials && pipeMaterials.filter(_ => !_.isDeleted).map((pm, idx) => 
                                    <option key={`pipe-opening-material-${idx}`} value={pm.value}>{pm.label}</option>
                                )}
                            </select>
                        </div>
                        {customPipeOpeningFormErrorState.material && customPipeOpeningFormErrorState.material.isError &&
                            <span className="help is-danger">{customPipeOpeningFormErrorState.material.errorMessage}</span>
                        }
                    </div>

                    <InputField
                        fieldLabel={`Inside Diameter`}
                        onChange={onChangeCustomPipeOpeningFormState}
                        fieldName={'insideDiameter'}
                        value={customPipeOpeningFormState.insideDiameter}
                        errorValue={customPipeOpeningFormErrorState.insideDiameter}
                        helpText={``}
                        isNumber={true}
                        hasAddOn={`mm`}
                        isExpanded={true} />     
                                                                
                    <InputField
                        fieldLabel={`Opening Size`}
                        onChange={onChangeCustomPipeOpeningFormState}
                        fieldName={'openingSize'}
                        value={customPipeOpeningFormState.openingSize}
                        errorValue={customPipeOpeningFormErrorState.openingSize}
                        helpText={``}
                        isNumber={true}
                        hasAddOn={`mm`}
                        isExpanded={true} />  

                    <InputField
                        fieldLabel={`Outside Diameter`}
                        onChange={onChangeCustomPipeOpeningFormState}
                        fieldName={'outsideDiameter'}
                        value={customPipeOpeningFormState.outsideDiameter}
                        errorValue={customPipeOpeningFormErrorState.outsideDiameter}
                        helpText={``}
                        isNumber={true}
                        hasAddOn={`mm`}
                        isExpanded={true} />
                                    
                    <div className="field is-grouped is-pulled-right pt-4">
                        <div className="control">
                            <button className={`button is-link`} onClick={onSubmitCustomPipeForm}>Add</button>
                        </div>
                        <div className="control">
                            <button className="button is-link is-light" onClick={onCancelModal}>Cancel</button>
                        </div>
                    </div>
                </form>
        </BaseModal>

        <CollapsibleFormSection
            headerTitle={``} 
            isOptional={false}
            isCollapsedByDefault={false}
            isCollapsible={false}
            body={
                <>
                <div className="field pb-1">
                    <label className="label">
                        <span>{`Product Type`}</span>
                    </label>
                    <div className={`control`}>
                        <Select 
                            autoFocus={true}
                            name={'productType'}
                            isDisabled={formView !== 'add'} 
                            value={{value: formState.productType, label: formState.productType}}
                            onChange={onChangeProductType}
                            isClearable={true}
                            filterOption={createFilter({
                                matchFrom: 'start'
                            })}
                            options={displayProductTypesGrouped()} />
                    </div>
                    <div className="columns is-vcentered mt-0 pt-0 mb-1">
                        <div className="column pt-1">
                        {formErrorState.productType && formErrorState.productType.isError && <p className={`help is-danger`}>{formErrorState.productType.errorMessage}</p>}
                        {formState.productType && productTypes.find(_ => _.value === formState.productType) && productTypes.find(_ => _.value === formState.productType).weight && <p className={`help is-info mt-0`}><FontAwesomeIcon icon={faCircleInfo} className="pr-1"/>{`Weight: ${productTypes.find(_ => _.value === formState.productType).weight.value}${productTypes.find(_ => _.value === formState.productType).weight.unitOfMeasurement}`}</p>}
                        </div>
                    </div>
                    
                </div>

                <InputField
                    fieldLabel={`Product Code`}
                    fieldName={'productCode'}
                    onChange={() => {}}
                    value={formState.productCode}
                    errorValue={null}
                    helpText={`The product code`}
                    isDisabled={true} 
                    isCopyEnabled={formState.productCode ? true : false} 
                    onClickCopy={formState.productCode ? onCopyProductCode : null} />

                <TextAreaInputField
                    fieldLabel={`Product Description`}
                    onChange={onChangeInput}
                    fieldName={'description'}
                    value={formState.description}
                    isDisabled={formView === 'view'}
                    errorValue={formErrorState.description}
                    helpText={`The description of the product`} 
                    isOptional={true} />

                <TagsInput
                    fieldLabel={`Tags`}
                    fieldName={`tags`}
                    errorValue={formErrorState.tags}
                    tags={formState.tags}
                    isDisabled={formView === 'view'}
                    onAddTag={(tag: string) => onAddTag('tags', tag)}
                    onDeleteTag={(tag: string) => onDeleteTag('tags', tag)}
                    isOptional={true} />

                </>
        } />


        <CollapsibleFormSection
            headerTitle={`Drawings`} 
            isOptional={false}
            isCollapsedByDefault={false}
            isCollapsible={false}
            body={
                <DesignDocumentsForm formView={formView} designDocumentsForm={designDocumentsForm} isMerchant={isMerchant} />
            } 
        />

        <CollapsibleFormSection
            headerTitle={`Product Details`} 
            isOptional={true}
            isCollapsedByDefault={formView === 'add' ? true : false}
            isCollapsible={true}
            body={
                <>
                <div className="field pb-1">
                    <label className="label">Toe <OptionalFieldFormLabel /></label> 
                    {toeBeamCodes.filter(_ => formState.productType ? _.eligibleProductTypes.some(ept => formState.productType.startsWith(ept)) : true).length === 0
                    ?
                        <p className="is-italic">No toe codes for this product type.</p>
                    :
                        <>
                        <div className={`select is-fullwidth ${formErrorState.toe.isError ? `is-danger`: ''}`}>
                            <select disabled={formView === 'view'} name="toe" value={formState.toe} onChange={onChangeInput}>
                                <option value="" disabled={true} >Select a toe</option>
                                {toeBeamCodes
                                .filter(_ => formState.productType ? _.eligibleProductTypes.some(ept => formState.productType.startsWith(ept)) : true)
                                .map((toe, idx) =>
                                    <option key={`toe-code-${idx}`} value={toe.code}>
                                        {toe.code} - {`${toe.width.value}${toe.width.unitOfMeasurement} x ${toe.depth.value}${toe.depth.unitOfMeasurement} x ${toe.height.value}${toe.height.unitOfMeasurement}`}
                                    </option>
                                )}
                                <option value={``}>{`No Toe`}</option>
                            </select>
                        </div>
                        </>
                    }
                    <div className="columns is-vcentered mt-0 pt-0 mb-1">
                        <div className="column pt-1">
                        {formErrorState.toe.isError && <span className="help is-danger">{formErrorState.toe.errorMessage}</span> }
                        {formState.toe && toeBeamCodes.find(_ => _.code === formState.toe) && <p className={`help is-info mt-0`}><FontAwesomeIcon icon={faCircleInfo} className="pr-1"/>{`Weight: ${toeBeamCodes.find(_ => _.code === formState.toe).weight.value}${toeBeamCodes.find(_ => _.code === formState.toe).weight.unitOfMeasurement}`}</p>}
                        </div>
                    </div>
                    
                </div>

                <div className="field pb-1">
                    <label className="label">Handrail <OptionalFieldFormLabel /></label> 
                    {formView === 'view' && !formState.handrail
                    ?
                    <span className="is-italic">None</span>
                    :
                    <div className={`select is-fullwidth ${formErrorState.handrail.isError ? `is-danger`: ''}`}>
                        <select disabled={formView === 'view'} name="handrail" value={formState.handrail} onChange={onChangeInput}>
                            <option value="" disabled={true} >Select a handrail</option>
                            {handrailCodes.map((hc, idx) =>
                                <option key={`handrail-code-${idx}`} value={hc.value}>
                                    {hc.label} - {hc.shortDescription}
                                </option>
                            )}
                            <option value={`KQ`}>{formState.productCode ? `KQ-${formState.productCode}` : `KQ`} - Special handrail</option>
                            <option value={`PQ`}>{formState.productCode ? `PQ-${formState.productCode}` : `PQ`} - Special handrail</option>
                            <option value={``}>{`No Handrail`}</option>
                        </select>
                    </div>
                    }

                    {formErrorState.handrail.isError &&
                        <span className="help is-danger">{formErrorState.handrail.errorMessage}</span>
                    }
                </div>

                <div className="field pb-1">
                    <label className="label">
                        <span>{`Steelwork Code(s)`}</span>
                        {<OptionalFieldFormLabel />}
                    </label>                                 


                    <AsyncPaginate
                        additional={defaultAdditional}
                        isMulti
                        loadOptions={loadOptionsSteelwork}
                        placeholder={''}
                        autoFocus={true}
                        components={{ MultiValueLabel }}
                        isDisabled={formView === 'view'} 
                        value={formState.steelworkCode?.map(_ => ({value: _, label: _}))}
                        onChange={onChangeSteelworkCode}
                        isClearable={true}
                        name="steelworkCode"
                        className="basic-multi-select"
                        classNamePrefix="select"
                    /> 

                </div>

                <div className="field pb-1">
                    <label className="label">
                        <span>{`Secondary Casting(s)`}</span>
                        {<OptionalFieldFormLabel />}
                    </label>                                 

                    <AsyncPaginate
                        additional={defaultAdditional}
                        isMulti
                        loadOptions={loadOptionsSecondaryCasting}
                        placeholder={''}
                        autoFocus={true}
                        components={{ MultiValueLabel }}
                        isDisabled={formView === 'view'} 
                        value={formState.secondaryCasting?.map(_ => ({value: _, label: _}))}
                        onChange={onChangeSecondaryCasting}
                        isClearable={true}
                        name="secondaryCasting"
                        className="basic-multi-select"
                        classNamePrefix="select"
                    /> 

                </div>

                <div className="field pb-1">
                    <label className="label">
                        <span>{`Reinforcing(s)`}</span>
                        {<OptionalFieldFormLabel />}
                    </label>                                 

                    <AsyncPaginate
                        additional={defaultAdditional}
                        isMulti
                        loadOptions={loadOptionsReinforcing}
                        placeholder={''}
                        autoFocus={true}
                        components={{ MultiValueLabel }}
                        isDisabled={formView === 'view'} 
                        value={formState.reinforcing?.map(_ => ({value: _, label: _}))}
                        onChange={onChangeReinforcing}
                        isClearable={true}
                        name="reinforcing"
                        className="basic-multi-select"
                        classNamePrefix="select"
                    /> 

                </div>

                </>
            } />



            <CollapsibleFormSection
                headerTitle={`Pipe Openings`} 
                isOptional={true}
                isCollapsedByDefault={formView === 'add' ? true : false}
                isCollapsible={true}
                body={
                    <>
                    {formState.pipeOpenings.map((pipe, idx) => (
                        <div key={idx}>
                            <div className="columns is-vcentered mb-0">

                                <div className={`column is-5 pt-0 pb-0`}> 
                                    <div className="field">
                                        {pipe.pipeType === 'CUSTOM'
                                        ?
                                        <>
                                            <label className="label">Custom Pipe</label> 
                                            <input className="input" type="text" placeholder={`${prettyDisplayValue(pipe.material)} - ${pipe.insideDiameter}mm`} disabled={formView === 'view'} readOnly={true} />
                                        </>
                                        :
                                        <>
                                            <label className="label">Select a pipe</label> 
                                            <div className={`select`}>
                                                <select disabled={formView === 'view'} name="pipeId" value={pipe.pipeId || ``} onChange={(e) => onChangePipe(e, idx, pipeOpenings)}>
                                                    <option value="" disabled={true} >Select a pipe</option>
                                                    <option key={`pipe-opening-CULVERT`} value="CULVERT">CULVERT</option>
                                                    {/* You shouldn't be able to select a deleted pipe opening when adding a product. TODO clear this up once we handle deletes properly. */}
                                                    {pipeOpenings.filter(_ => !_.isDeleted || _.id === pipe.pipeId) 
                                                        .sort((a, b) => {
                                                            if (a.material.toUpperCase() > b.material.toUpperCase()) return 1;
                                                            if (a.material.toUpperCase() < b.material.toUpperCase()) return -1;
                                                            return 0;
                                                        })
                                                        .map((po, idx) => 
                                                            <option key={`pipe-opening-${idx}`} value={po.id}>{`${pipeMaterials && pipeMaterials.find(_ => _.value === po.material).label} - ${po.insideDiameter}mm`}</option>
                                                        )
                                                    }
                                                    <option value={``}>{`No Pipe Opening`}</option>
                                                </select>
                                            </div>
                                        </>
                                        }
                                        {formErrorState.pipeOpenings[idx] && formErrorState.pipeOpenings[idx].pipeId && formErrorState.pipeOpenings[idx].pipeId.isError &&
                                            <span className="help is-danger">{formErrorState.pipeOpenings[idx].pipeId.errorMessage}</span>
                                        }
                                    </div>
                                </div>

                                <div className="column pb-0">
                                    <div className="columns is-vcentered mb-0 pt-2 field is-grouped">

                                        {/* Culvert pipes are treated differently and for them the width and height is needed as opposed to O/L and I/L for all other pipes */}
                                        {isCulvertPipe(pipe.pipeType)
                                        ?
                                        <>
                                            <div className={`column`}>
                                                <InputField
                                                    fieldLabel={`Width`}
                                                    onChange={(e) => onChangeWidth(e, idx)}
                                                    fieldName={'width'}
                                                    value={!isNaN(pipe.width) ? pipe.width : ''}
                                                    isDisabled={formView === 'view' || formState.pipeOpenings[idx].pipeId === ''}
                                                    errorValue={formErrorState.pipeOpenings[idx] && formErrorState.pipeOpenings[idx].width}
                                                    helpText={`The width of the pipe`}
                                                    isNumber={true}
                                                    hasAddOn={`mm`}
                                                    isShowErrorValue={false} />
                                            </div>                                    

                                            <div className={`column`}>
                                                <InputField
                                                    fieldLabel={`Height`}
                                                    onChange={(e) => onChangeHeight(e, idx)}
                                                    fieldName={'height'}
                                                    value={!isNaN(pipe.height) ? pipe.height : ''}
                                                    isDisabled={formView === 'view' || formState.pipeOpenings[idx].pipeId === ''}
                                                    errorValue={formErrorState.pipeOpenings[idx] && formErrorState.pipeOpenings[idx].height}
                                                    helpText={`The height of the pipe`}
                                                    isNumber={true}
                                                    hasAddOn={`mm`}
                                                    isShowErrorValue={false} />
                                            </div>
                                        </>
                                        :
                                        <>
                                            <div className={`column`}>
                                                <InputField
                                                    fieldLabel={`Opening Level`}
                                                    onChange={(e) => onChangeOpeningLevel(e, idx)}
                                                    fieldName={'openingLevel'}
                                                    value={!isNaN(pipe.openingLevel) ? pipe.openingLevel : ''}
                                                    isDisabled={formView === 'view' || formState.pipeOpenings[idx].pipeId === ''}
                                                    errorValue={formErrorState.pipeOpenings[idx] && formErrorState.pipeOpenings[idx].openingLevel}
                                                    helpText={`The opening level of the pipe`}
                                                    isNumber={true}
                                                    hasAddOn={`mm`}
                                                    isShowErrorValue={false} />
                                            </div>                                    

                                            <div className={`column`}>
                                                <InputField
                                                    fieldLabel={`Invert Level`}
                                                    onChange={null}
                                                    fieldName={'invertLevel'}
                                                    value={!isNaN(pipe.invertLevel) ? pipe.invertLevel : ''}
                                                    isDisabled={true}
                                                    errorValue={formErrorState.pipeOpenings[idx] && formErrorState.pipeOpenings[idx].invertLevel}
                                                    helpText={`The invert level of the pipe`}
                                                    isNumber={true}
                                                    hasAddOn={`mm`}
                                                    isShowErrorValue={false} />
                                            </div>
                                        </>
                                        }
                                    </div>
                                    {formErrorState.pipeOpenings[idx] && formErrorState.pipeOpenings[idx].width && formErrorState.pipeOpenings[idx].width.isError
                                    ? <p className={`help is-danger mt-0`}><FontAwesomeIcon icon={faWarning} className="pr-1"/>{formErrorState.pipeOpenings[idx].width.errorMessage}</p> 
                                    : formErrorState.pipeOpenings[idx] && formErrorState.pipeOpenings[idx].height && formErrorState.pipeOpenings[idx].height.isError 
                                    ? <p className={`help is-danger mt-0`}><FontAwesomeIcon icon={faWarning} className="pr-1"/>{formErrorState.pipeOpenings[idx].height.errorMessage}</p> 
                                    : null
                                    }
                                </div>
                            
                                {formView !== 'view' &&
                                    <div className="column is-narrow">
                                        {formState.pipeOpenings.length > 1 &&
                                            <span className="is-clickable" onClick={(e: React.MouseEvent) => onDeleteFromArray(e, idx, 'pipeOpenings')}>{<FontAwesomeIcon icon={faXmark} />}</span>
                                        }
                                    </div>
                                }

                            </div>

                            <div className="columns is-vcentered mt-0 pt-0 mb-1">
                                <div className="column pt-0">
                                {formErrorState.pipeOpenings[idx] && formErrorState.pipeOpenings[idx].openingLevel && formErrorState.pipeOpenings[idx].openingLevel.isError ? <p className={`help is-danger mt-0`}><FontAwesomeIcon icon={faWarning} className="pr-1"/>{formErrorState.pipeOpenings[idx].openingLevel.errorMessage}</p> : null }
                                {(!isCulvertPipe(pipe.pipeType) && pipe.pipeId !== ``) && <p className={`help is-info mt-0`}><FontAwesomeIcon icon={faCircleInfo} className="pr-1"/>{`opening size: ${pipe.openingSize}mm, outside diameter: ${pipe.outsideDiameter}mm, inside diameter: ${pipe.insideDiameter}mm, pipe clearance: ${computePipeClearance(pipe.openingSize, pipe.outsideDiameter)}mm`}</p>}
                                {/* TODO - clear this up once we handle deletes properly */}
                                {pipe.pipeId !== `` && pipeOpenings.find(_ => _.id === pipe.pipeId)?.isDeleted && <p className={`help is-danger mt-0`}><FontAwesomeIcon icon={faWarning} className="pr-1"/>{`WARNING: This product is using a pipe opening that has since been deleted.`}</p>}
                                </div>
                            </div>

                        </div>
                    ))}

                    {displayNoneIfEmptyAndInViewState(formState.pipeOpenings)}

                    {formView !== 'view' &&
                        <div className="field is-grouped">
                            <div className="control">
                                <AddAnotherBtn onClick={(e) => onAddObjectToArray(e, 'pipeOpenings', initialPipeOpening)} />
                            </div>
                            <div className="control">
                                <button onClick={() => setIsAddCustomPipeOpeningModalActive(true)} className="button is-small is-text">Add a custom pipe <span>{<FontAwesomeIcon icon={faAdd} className="pl-2" />}</span></button>
                            </div>
                        </div>
                    }
                    </>
                } />
    

            <CollapsibleFormSection
                headerTitle={`Flow Control Devices`} 
                isOptional={true}
                isCollapsedByDefault={formView === 'add' ? true : false}
                body={
                    <>
                    {formState.flowControlDevices.map((fcd, idx) => (
                        <div key={`fcd-${idx}`}>
                        <div className="columns is-vcentered" key={idx}>

                            <div className="column pb-2">
                                <div className="field">
                                    <label className="label">Type</label> 
                                    <div className={`select is-fullwidth ${formErrorState.flowControlDevices[idx] &&  formErrorState.flowControlDevices[idx].type && formErrorState.flowControlDevices[idx].type.isError ? `is-danger`: ''}`}>
                                        <select disabled={formView === 'view'} name="type" value={fcd.type} onChange={(e) => onChangeFlowControlDevice(e, idx)}>
                                            <option value="" disabled={true} >Select a type</option>
                                            {flowControlDeviceTypes.map((fcd, idx) => 
                                                <option key={`flow-control-device-type-${idx}`} value={fcd.value}>{fcd.label}</option>
                                            )}
                                                <option value={``}>{`No Device`}</option>  
                                        </select>
                                    </div>
                                    {formErrorState.flowControlDevices[idx] && formErrorState.flowControlDevices[idx].type && formErrorState.flowControlDevices[idx].type.isError &&
                                        <span className="help is-danger">{formErrorState.flowControlDevices[idx].type.errorMessage}</span>
                                    }
                                </div>
                            </div>

                            <div className={`column  pb-0`}> 
                                <InputField
                                    fieldLabel={`Size`}
                                    onChange={(e) => onChangeObjectInArray(e, 'flowControlDevices', idx)}
                                    fieldName={'size'}
                                    value={fcd.size}
                                    isDisabled={formView === 'view'}
                                    errorValue={formErrorState.flowControlDevices[idx] && formErrorState.flowControlDevices[idx].size}
                                    helpText={`The size of the flow control device`} 
                                    isNumber={true} 
                                    hasAddOn={`mm`} />
                            </div>
                        
                            {formView !== 'view' &&
                                <div className="column is-narrow">
                                    {formState.flowControlDevices.length > 1 &&
                                        <span className="is-clickable" onClick={(e: React.MouseEvent) => onDeleteFromArray(e, idx, 'flowControlDevices')}>{<FontAwesomeIcon icon={faXmark} />}</span>
                                    }
                                </div>
                            }
                            
                        </div>
                        {(fcd.type === 'FLAP_VALVE' || fcd.type === 'PENSTOCK') &&
                            <div className="columns is-vcentered">
                                <div className="column">
                                    <InputField
                                        fieldLabel={`Manufacturer`}
                                        onChange={(e) => onChangeObjectInArray(e, 'flowControlDevices', idx)}
                                        fieldName={'manufacturer'}
                                        value={fcd.manufacturer}
                                        isDisabled={formView === 'view'}
                                        errorValue={formErrorState.flowControlDevices[idx] && formErrorState.flowControlDevices[idx].manufacturer}
                                        helpText={`The manufacturer of the flow control device`} 
                                        isOptional={true} />
                                </div>
                            </div>
                        }
                        </div>
                    ))}

                    {displayNoneIfEmptyAndInViewState(formState.flowControlDevices)}

                    {formView !== 'view' &&
                        <div className="field mt-5">
                            <AddAnotherBtn onClick={(e) => onAddObjectToArray(e, 'flowControlDevices', initialFlowControlDevice)} />
                        </div>
                    }   
                    </>
                } />
        </>

    )
}

export { ProductForm }